.testPageLayout {
  max-width: 600px;
  color: #1d3867;
  text-align: initial;
}

.testPageIcon {
  margin-right: 5px;
}

.testPageWarning {
  margin-top: 10px;
}

.testPageTitle {
  text-align: center;
}

.testPageDescription {
  text-align: left;
  white-space: pre-line;
}

.disabledSelect {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
