.labelRoot {
  color: #1d3867;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.required {
  color: #ff7461;
}
