.formFieldRoot {
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.childrenContainer {
  min-width: 0;
}

.formFieldError {
  padding-top: 3px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff7461;
}

.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
