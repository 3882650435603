.inputRoot {
  border: 1px solid #dadfef;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &.hasValue {
    background: #fff;
  }
  &.hovered {
    background: #fff;
    border-color: rgba(55, 156, 254, 0.4);
  }
  &.focused {
    background: #fff;
    border-color: rgba(55, 156, 254, 0.8);
  }
  &.inputError {
    border-color: rgba(255, 116, 97, 0.8);
  }
}

.input {
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 9px 15px;
  line-height: 18px !important;
  background: #fff;
  outline: none !important;
  color: rgba(20, 20, 20, 0.8);

  &::placeholder {
    color: #bcc1d1;
    user-select: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.inputInner {
  display: flex;
}

/* hide arrows for number input */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* hide arrows for number input */
