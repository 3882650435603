.buttonRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none !important;
  border-radius: 10px;
  position: relative;
  user-select: none;
  border: none;

  padding: 10px 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;

  background-color: #2388e1;
  color: #fff;

  &:hover {
    box-shadow: 0 0 0 2px rgba(62, 160, 254, 0.3);
  }

  &:active,
  &:focus {
    background-color: #2388e1;
  }

  > .buttonSpinnerWrapper {
    background-color: #2388e1;
    .buttonSpinnerColor {
      color: #fff;
    }
  }
}

.disabledButton {
  pointer-events: none;
  background-color: #eeeff0;
  color: #9b9da6 !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
}
