form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 801px) {
  form {
    padding: 0 15%;
  }
}
