.radioGroupContainer {
  display: flex;
  flex-direction: column;
}

.radioLabel {
  color: #1d3867;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  flex-wrap: nowrap !important;
  margin: 0 0 12px 0;
}

.radioLabelWrapper {
  margin: 0 0 2px 5px;
}

.radioRoot {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &.radioHovered {
    &:after {
      content: "";
      position: absolute;
      top: -7px;
      bottom: -6px;
      left: -7px;
      right: -6px;
      border-radius: 50%;
      background: rgba(143, 155, 179, 0.08);
    }
  }
  &.radioFocused {
    &:after {
      content: "";
      position: absolute;
      top: -7px;
      bottom: -6px;
      left: -7px;
      right: -6px;
      border-radius: 50%;
      background: rgba(143, 155, 179, 0.16);
    }
  }
}

.radioIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.radioInput {
  opacity: 0;
  position: absolute;
}
